
.clues-selected-text {
    text-align: center;
    /* font-weight: bold; */
    color: black;
    background: white;
    border: 2px solid black;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    text-align: left;
}

.clues-selected-text-number {
    color: white;
    background: black;
    padding: 5px;
    border-radius: 10px;
}


.clues {
    display: flex;
    flex-direction: row;
    column-gap: 0px;
    height: calc(100% - 110px);
    max-width: min(100vh, 100vw);
}

.clue {
    padding: 5px;
    text-align: left;
    cursor: pointer;
}

.clueList {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    /* padding: 10px; */
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-left: 1px solid #000000;
}

.selected-clue {
    background: #FFC857;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid #f8c04f;
}

.clueDirLabel {
    font-weight: bold;
    text-align: center;
    color: white;
    background: black;
    width: 100%;
}
