
.selected > .cell {
    fill: #c0c0c0;
}

.guess {
    text-transform: uppercase;
}


.pencil {
    font-style: italic;
    fill: #ababab;
}


@media screen and (orientation:portrait) {
    #board-svg {
        width: calc(100vw - 2px);
        max-width:min(100%, calc(100vh - 200px - 50px - 100px - var(--sal) - var(--sar)));
    }
    #board {
        width: calc(100vw);
        height: calc(100vh - var(--sab) - var(--sat) - var(--kbh));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        justify-items: center;
    }
    
}

@media screen and (orientation:landscape) {
    #board {
        width: calc(100%);
        max-width:calc(min(100vw, 100vh) - 100px - 60px - 40px);
    }
}

