@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Chakra+Petch&family=Croissant+One&family=Dancing+Script&family=Gloock&family=Julius+Sans+One&family=Nanum+Pen+Script&family=Patrick+Hand&family=Press+Start+2P&family=Sedgwick+Ave+Display&display=swap');

@font-face {
  font-family: 'Penisland'; /* Give it a name */
  src: url('./assets/Cocksure.ttf') format('truetype'); /* Relative path to your font file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Softies'; /* Give it a name */
  src: url('./assets/Semi.otf') format('opentype'); /* Relative path to your font file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Halloween'; /* Give it a name */
  src: url('./assets/halloween.otf') format('opentype'); /* Relative path to your font file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Holiday'; /* Give it a name */
  src: url('./assets/christmabet.ttf') format('truetype'); /* Relative path to your font file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SouthernPark'; /* Give it a name */
  src: url('./assets/south-park-smilie-version.ttf') format('truetype'); /* Relative path to your font file */
  font-weight: normal;
  font-style: normal;
}

/*
font-family: 'Caveat', cursive;
font-family: 'Chakra Petch', sans-serif;
font-family: 'Croissant One', cursive;
font-family: 'Dancing Script', cursive;
font-family: 'Gloock', serif;
font-family: 'Julius Sans One', sans-serif;
font-family: 'Nanum Pen Script', cursive;
font-family: 'Patrick Hand', cursive;
font-family: 'Press Start 2P', cursive;
font-family: 'Sedgwick Ave Display', cursive;
*/


html, body {
  overflow: hidden;
  /* background: #395B50; */
  background: black;
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
  --sal: env(safe-area-inset-left);
  --kbh: 350px;
  margin-top: var(--sab);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (orientation:landscape) {
  html, body {
    background: #395B50 !important;
  }
}


.date-picker {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1rem;
  /* text-align:right; */
  cursor: pointer;
  outline: none;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
}

.share-button {
  /* font-size: 20px; */
  /* padding: 10px; */
  /* margin-right: -10px; */
}

.social-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.extra-buttons {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}