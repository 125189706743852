


@media screen and (orientation:portrait) {
    #game {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 55px - 40px - var(--sab) - var(--sat));
        padding: 0px;
        overflow:hidden;
        justify-content: space-between;
    }
    
    .keyboard {
        width: 100vw;
    }
}

@media screen and (orientation:landscape) {
    #game {
        display: flex;
        flex-direction: row;
        height: calc(100vh - 70px - 50px - 40px);
        padding: 30px;
        overflow:hidden;
        justify-content: space-between;
    }
    
    .keyboard {
        width: 100vw;
    }
}


.simple-keyboard.hg-layout-default .hg-button.hg-dark {
    background: #232323 !important;
    color: white;
    border-bottom: none;
  }
  
.simple-keyboard.hg-theme-default.darkKeyboard{
    background: black;
  }

.simple-keyboard.hg-layout-default .hg-button.hg-p-select{
    border: 1px solid #ababab;
    background: #c0c0c0;
}

.simple-keyboard.hg-layout-default .hg-button.hg-p-delete{
    font-size: 1.5em;
    /* background: #f7dada; */
}