
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 55px);
}

.version-info {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    opacity: 0.5;
}

.refresh-button {
    padding: 0px;
    margin: 0px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    margin-top: 5px;
    cursor: pointer;
}