

.user-badge {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.user-badge-verified {
    fill: "#53af4d";
    position: absolute;
    top: -2px;
    left: 4px;
}

.social-bar-user-label {
    min-width: 10px;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    white-space: nowrap;
}

.online-dot {
    width: 7px;
    height: 7px;
    min-width: 7px;
    min-height: 7px;
    border-radius: 50%;
    background: #53af4d;
    margin-top: -8px;
    margin-left: -8px;
}


.verified-badge {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    align-items: center;
}

.verified-badge::after {
    content: '✓';
    color: rgb(0, 184, 21);
    font-weight: bold;
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    margin-left: -2px;
    margin-top: -6px;
}