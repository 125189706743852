
.clueboard-container {
    background:rgb(255, 247, 235);
    position: absolute;
    top: 95px + var(--sat);
    width: 100%;
    height: calc(100vh - var(--sab) - var(--sat) - var(--kbh));
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid #dadada;
}

.clue-row {
    width: 100%;
    border-bottom: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50px;
    padding: 5px;
    position: relative;
}

.clue-row-label {
    padding: 5px;
    display: flex;
    flex-direction: row;
}

.clue-row-answer {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.clue-row-answer-cell {
    border: 2px solid black;
    height: 20px;
    min-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    background: white;
    overflow:hidden;
    text-transform: uppercase;
}

.clue-row-dots {
    display: flex;
    flex-direction: row;
    padding: 5px;
    column-gap: 3px;
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 2px;
}

.clue-row-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}