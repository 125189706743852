

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
}

.error-icon {
    margin-top: -200px;
    font-size: 124px;
    opacity: 0.5;
}

.error-message {
    font-size: 24px;
    margin-top: 20px;
    opacity: 0.5;
    padding: 20px;
    text-align: center;
}