
.install-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh);
    width: calc(100vw - 40px);
    background: #395B50;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    color: white;
    padding: 20px;
}

.install-info-logo {
    text-align:center;
    margin-bottom: 20px;
}

.install-info-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align:center;
}

.install-info-subtitle {
    font-size: 14px;
    margin-bottom: 30px;
    text-align:center;
}

.room-name {
    color: #FFC857;
    font-weight: bold;
}

.install-info-instructions {
    font-size: 12px;
    margin-bottom: 10px;
    text-align:center;
}