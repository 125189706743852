
.social-bar {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
}

.social-bar-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 2px;
    font-size: 12px;
    /* min-width: 70px; */
    /* max-width: 200px; */
    width: auto;
    height: 38px !important;
    max-height: 38px !important;
    /* overflow: hidden; */
}

.social-bar-user-offline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 2px;
    font-size: 12px;
    color: #888888;
}

.social-bar-user-color {
    width: 11px;
    height: 11px;
    min-width: 11px;
    min-height: 11px;
    border-radius: 50%;
}

.social-bar-online {
    max-width: calc(100% - 90px);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    column-gap: 5px;
}


.social-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 0px;
}

#chatButton {
    font-size: 20px;
    padding: 0px;
    width: 30px;
    min-width: 50px;
    cursor: pointer;
    color: #888888;
}

.social-bar-speech-bubble {
  position: absolute;
  top: calc(var(--sab) + var(--sat) - 200px);
  /* center */
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  padding: 10px;
  border-radius: 10px;
  background: white;
  z-index: 20;
  font-size: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75); 
  transition: all 0.5s ease-in-out;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-delay: 800ms;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  justify-content: center;
  align-items: center;
}

.social-bar-speech-bubble > .social-bar-user-color {
  align-self: flex-start;
  margin-top: 2px;
}

.bounceAnim {
    /* -webkit-animation: wobble-ver-left 0.9s 1 ease-in-out; */
    /* -o-animation: wobble-ver-left 0.9s 1 ease-in-out; */
    /* -ms-animation: wobble-ver-left 0.9s 1 ease-in-out;  */
    /* -moz-animation: wobble-ver-left 0.9s 1 ease-in-out;  */
    /* animation: wobble-ver-left 0.9s 1 ease-in-out; */
}
.bounceAnim > *{
    color: #007bff;
}

#social-menu {
    font-size: 24px;
    padding: 0px;
    width: 30px;
    min-width: 30px;
}

@-webkit-keyframes bounce {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}

@keyframes bounce {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(1deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-1deg); }
    100% { transform: rotate(0deg); }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-13 20:26:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-left
 * ----------------------------------------
 */
 @-webkit-keyframes wobble-ver-left {
    0%,
    100% {
      -webkit-transform: translateY(0) rotate(0);
              transform: translateY(0) rotate(0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateY(-30px) rotate(-6deg);
              transform: translateY(-30px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateY(15px) rotate(6deg);
              transform: translateY(15px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateY(-15px) rotate(-3.6deg);
              transform: translateY(-15px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateY(9px) rotate(2.4deg);
              transform: translateY(9px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateY(-6px) rotate(-1.2deg);
              transform: translateY(-6px) rotate(-1.2deg);
    }
  }
  @keyframes wobble-ver-left {
    0%,
    100% {
      -webkit-transform: translateY(0) rotate(0);
              transform: translateY(0) rotate(0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateY(-30px) rotate(-6deg);
              transform: translateY(-30px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateY(15px) rotate(6deg);
              transform: translateY(15px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateY(-15px) rotate(-3.6deg);
              transform: translateY(-15px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateY(9px) rotate(2.4deg);
              transform: translateY(9px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateY(-6px) rotate(-1.2deg);
              transform: translateY(-6px) rotate(-1.2deg);
    }
  }
  


  /* ----------------------------------------------
 * Generated by Animista on 2023-9-21 13:43:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
