
.chat-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: calc(100% - 120px);
    min-width: 300px;
    border: 1px solid #dadada;
    border-radius: 15px 15px 15px 15px;
    margin: 10px;
}

.chat-container-mobile {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: static;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100%);
    min-height: calc(100%);
    z-index: 99;
    border-top: 1px solid #ababab;
    border-radius: 15px 15px 15px 15px;
}



.chat-history {
    display: flex;
    flex-direction: column;
    background: white;
    overflow-y: auto;
    padding: 10px;
    height: calc(100% - 100px);
    border-radius: 15px 15px 5px 5px;
    /* justify-content: flex-end; */
}


.chat-message-container, .chat-message-container-me {
    margin-bottom: 10px;
    width: 80%;
}

.chat-message-container-me {
    margin-left: 20%;
}

.chat-message-system { 
    background: #FFFFFF;
    color: #000000;
    border-radius: 3px;
    margin-top: 1px;
    margin-bottom:1px;
}

.chat-message-system > .chat-message { 
    font-size: 12px;
    font-weight: bold;;
    font-family: 'Courier New', Courier, monospace;
    background: rgba(0,0,0,0)!important;
    color: #000000;
}

.chat-username {
    font-weight: bold;
    font-size: 10px;
    color: #888888;
    padding: 4px;
}

.chat-username > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.chat-message {
    font-size: 14px;
    color: #000000;
    padding: 10px;
    background: #dadada;
    border-radius: 10px;
}

.chat-message-emoji {
    font-size: 42px;
    padding: 10px;
    background-color: rgba(0,0,0,0) !important;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.chat-timestamp {
    font-size: 10px;
    color: #888888;
    padding: 4px;
    text-align:right;

}

.chat-verified {
    text-align: left;
    margin-bottom: -12px;
    font-style: italic;
}

.message-composer-container {
    background: #395B50;
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 15px 15px 15px 15px;
}

.message-composer-attachment, .message-composer-send {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
    color: white;
}

.message-composer-attachment-header {
    font-size: 12px;
    font-weight: bold;
    background: #6ea7bd;
    padding: 20px;
    padding-top: 4px;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: -15px;
    color: white;
    display: flex;
    align-items: center;
}

.chat-attachment {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 10px;
    column-gap: 10px;
    cursor: pointer;
    border: 1px solid #ababab;
    border-radius: 10px;
    margin-bottom: 2px;
}

.chat-attachment-header {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    padding: 10px;
    column-gap: 10px;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
}

.chat-clue-block {
    border: 2px solid black;
    min-width: 16px;
    min-height: 16px;
    color: black;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
}

.clue-attachment-word {
    display:flex;
    flex-direction: row;
    min-width: 200%;
}

.clue-attachment-answer {
    height: 42px;
    color: black;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    /* hide scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.chat-attachment-number {
    font-size: 16px;
    font-weight: bold;
}

.chat-attachment-text {
    font-size: 12px;
    font-style: italic;
    width: calc(100% - 30px);
}

.chat-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 40px);
    color: #ababab;
}

.chat-empty-icon {
    font-size: 72px;
    color: #ababab;
}

.chat-empty-subtext {
    font-size: 12px;
}

.chat-empty-button {
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: -30px;
}