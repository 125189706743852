.clue-scroller {
    display: flex;
    flex-direction: row;
    width: 100vw;
    background: rgb(160, 160, 253);
    height: 50px;
    padding-bottom: 10px;
    padding-top: 10px;
}


.clue-button {
    width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.clue-scroller-text {
    width: calc(100% - 100px);
    height: 100%;
    /* display: flex; */
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    overflow-y: auto;
}

.clue-scroller-p {
    text-align: center;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    min-height: 100%;
    flex-direction: column;
}

.clue-sub {
    font-size: 0.8em;
    text-transform: italic;
    background: rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    border: 0px solid rgba(0,0,0,0.0);
}

.clue-sub > legend {
    font-weight: bold;
}